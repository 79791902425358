import { conform, useForm } from '@conform-to/react'
import { getFieldsetConstraint, parse } from '@conform-to/zod'
import * as E from '@react-email/components'
import {
	json,
	redirect,
	type DataFunctionArgs,
	type MetaFunction,
} from '@remix-run/node'
import { Form, useActionData, useSearchParams } from '@remix-run/react'
import { AuthenticityTokenInput } from 'remix-utils/csrf/react'
import { HoneypotInputs } from 'remix-utils/honeypot/react'
import { safeRedirect } from 'remix-utils/safe-redirect'
import { z } from 'zod'
import { ErrorList, Field, TshirtSelectField } from '#app/components/forms.tsx'
import { StatusButton } from '#app/components/ui/status-button.tsx'
import {
	IAGT_WHATSAPP_NEWS_LINK,
	IAGT_WHATSAPP_SOCIAL_LINK,
} from '#app/constants.ts'
import { requireAnonymous, sessionKey, signup } from '#app/utils/auth.server.ts'
import { redirectWithConfetti } from '#app/utils/confetti.server.ts'
import { validateCSRF } from '#app/utils/csrf.server.ts'
import { prisma } from '#app/utils/db.server.ts'
import { sendEmail } from '#app/utils/email.server.ts'
import { checkHoneypot } from '#app/utils/honeypot.server.ts'
import { invariant, useIsPending } from '#app/utils/misc.tsx'
import { authSessionStorage } from '#app/utils/session.server.ts'
import { NEW_USER_THREAD_ID } from '#app/utils/telegram.constants.ts'
import { sendTelegramMessage } from '#app/utils/telegram.server.ts'
import {
	FirstNameSchema,
	LastNameSchema,
	PasswordAndConfirmPasswordSchema,
	PhoneNumberSchema,
	EmailSchema,
	HandicapSchema,
	GHINSchema,
	TshirtSchema,
} from '#app/utils/user-validation.ts'
import { verifySessionStorage } from '#app/utils/verification.server.ts'
import { type VerifyFunctionArgs } from './verify.tsx'

const onboardingEmailSessionKey = 'onboardingEmail'

const SignupFormSchema = z
	.object({
		email: EmailSchema,
		firstName: FirstNameSchema,
		lastName: LastNameSchema,
		phoneNumber: PhoneNumberSchema,
		handicapIndex: HandicapSchema,
		ghin: GHINSchema,
		tshirtSize: TshirtSchema,
		remember: z.boolean().optional(),
		redirectTo: z.string().optional(),
	})
	.and(PasswordAndConfirmPasswordSchema)

export async function loader({ request }: DataFunctionArgs) {
	await requireAnonymous(request)
	return null
}

export async function action({ request }: DataFunctionArgs) {
	const formData = await request.formData()
	await validateCSRF(formData, request.headers)
	checkHoneypot(formData)
	let emailAddress = null
	let name = null
	let fullName = null

	const submission = await parse(formData, {
		schema: intent =>
			SignupFormSchema.superRefine(async (data, ctx) => {
				fullName = `${data.firstName} ${data.lastName}`
				emailAddress = data.email
				name = `${data.firstName}`
				const existingUser = await prisma.user.findUnique({
					where: { email: data.email },
					select: { id: true },
				})
				if (existingUser) {
					ctx.addIssue({
						path: ['username'],
						code: z.ZodIssueCode.custom,
						message: 'A user already exists with this username',
					})
					return
				}
			}).transform(async data => {
				if (intent !== 'submit') return { ...data, session: null }

				const session = await signup({ ...data })
				return { ...data, session }
			}),
		async: true,
	})

	if (submission.intent !== 'submit') {
		return json({ status: 'idle', submission } as const)
	}
	if (!submission.value?.session) {
		return json({ status: 'error', submission } as const, { status: 400 })
	}

	const { session } = submission.value

	const authSession = await authSessionStorage.getSession(
		request.headers.get('cookie'),
	)
	authSession.set(sessionKey, session.id)
	const verifySession = await verifySessionStorage.getSession()
	const headers = new Headers()
	headers.append(
		'set-cookie',
		await authSessionStorage.commitSession(authSession, {
			expires: session.expirationDate,
		}),
	)
	headers.append(
		'set-cookie',
		await verifySessionStorage.destroySession(verifySession),
	)

	if (emailAddress && name) {
		await sendEmail({
			to: emailAddress,
			subject: `RE: IAGT Account Created`,
			react: <WelcomeEmail name={name} />,
		})
	}

	if (fullName) {
		await sendTelegramMessage(
			`🆕👤 *${fullName}* signed up!`,
			NEW_USER_THREAD_ID,
		)
	}

	return redirectWithConfetti(safeRedirect('/onboarding/success'), { headers })
}

export function WelcomeEmail({ name }: { name: string }) {
	return (
		<E.Html lang="en" dir="ltr">
			<E.Container>
				<h1>
					<E.Text>Welcome to IAGT!</E.Text>
				</h1>
				<p>
					<E.Text>
						High-Five <strong>{name}</strong>! Congrats on creating your IAGT
						account. You are a step closer to being part of the Elite Desi
						Golfing Group in Chicagoland!
					</E.Text>
				</p>
				<p>
					<E.Text>
						Please join the IAGT WhatsApp group as all key communications happen
						there:
					</E.Text>
				</p>
				<ul>
					<li>
						<E.Link href={IAGT_WHATSAPP_NEWS_LINK}>
							IAGT News & Communication
						</E.Link>
					</li>
					<li>
						<E.Link href={IAGT_WHATSAPP_SOCIAL_LINK}>IAGT Social Group</E.Link>
					</li>
				</ul>
				<p>
					<E.Text>See you on the golf course soon!!</E.Text>
				</p>
				<p>
					<E.Text>
						<strong>IAGT Organizers</strong>
					</E.Text>
				</p>
			</E.Container>
		</E.Html>
	)
}

export async function handleVerification({ submission }: VerifyFunctionArgs) {
	invariant(submission.value, 'submission.value should be defined by now')
	const verifySession = await verifySessionStorage.getSession()
	verifySession.set(onboardingEmailSessionKey, submission.value.target)
	return redirect('/onboarding', {
		headers: {
			'set-cookie': await verifySessionStorage.commitSession(verifySession),
		},
	})
}

export const meta: MetaFunction = () => {
	return [{ title: 'Setup IAGT Account' }]
}

export default function SignupRoute() {
	const actionData = useActionData<typeof action>()
	const isPending = useIsPending()
	const [searchParams] = useSearchParams()
	const redirectTo = searchParams.get('redirectTo')

	const [form, fields] = useForm({
		id: 'onboarding-form',
		constraint: getFieldsetConstraint(SignupFormSchema),
		defaultValue: { redirectTo },
		lastSubmission: actionData?.submission,
		onValidate({ formData }) {
			return parse(formData, { schema: SignupFormSchema })
		},
		shouldRevalidate: 'onBlur',
	})

	return (
		<div className="container flex min-h-full flex-col justify-center pb-4 pt-2 sm:pb-32 sm:pt-20">
			<div className="mx-auto w-full max-w-lg">
				<div className="mb-2 flex flex-col gap-1 text-center">
					<h1 className="text-h1">Welcome aboard!</h1>
					<p className="text-body-md text-muted-foreground">
						Please enter your details.
					</p>
				</div>
				<Form
					method="POST"
					className="mx-auto min-w-full max-w-sm sm:min-w-[368px]"
					{...form.props}
				>
					<AuthenticityTokenInput />
					<HoneypotInputs />
					<Field
						labelProps={{
							htmlFor: fields.email.id,
							children: 'Email',
						}}
						inputProps={{
							...conform.input(fields.email),
							autoFocus: true,
							placeholder: 'user@example.com',
						}}
						errors={fields.email.errors}
					/>
					<div className="flex justify-between gap-4">
						<Field
							labelProps={{
								htmlFor: fields.firstName.id,
								children: 'First Name',
							}}
							inputProps={{
								...conform.input(fields.firstName),
								autoComplete: 'first name',
								placeholder: 'John',
							}}
							errors={fields.firstName.errors}
						/>
						<Field
							labelProps={{
								htmlFor: fields.lastName.id,
								children: 'Last Name',
							}}
							inputProps={{
								...conform.input(fields.lastName),
								autoComplete: 'last name',
								placeholder: 'Doe',
							}}
							errors={fields.lastName.errors}
						/>
					</div>
					<div className="flex justify-between gap-4">
						<Field
							labelProps={{
								htmlFor: fields.handicapIndex.id,
								children: 'Handicap Index',
							}}
							inputProps={{
								...conform.input(fields.handicapIndex),
								placeholder: '(0 to 54)',
							}}
							errors={fields.handicapIndex.errors}
						/>
						<TshirtSelectField
							labelProps={{
								htmlFor: fields.tshirtSize.id,
								children: 'T-shirt Size',
							}}
							selectProps={{
								...conform.input(fields.tshirtSize),
								autoComplete: 'tshirt size',
							}}
							errors={fields.tshirtSize.errors}
						/>
					</div>
					<Field
						labelProps={{
							htmlFor: fields.ghin.id,
							children: 'Handicap ID',
						}}
						inputProps={{
							...conform.input(fields.ghin),
							placeholder: '12345678',
						}}
						errors={fields.ghin.errors}
					/>
					<Field
						labelProps={{
							htmlFor: fields.phoneNumber.id,
							children: 'Phone Number',
						}}
						inputProps={{
							...conform.input(fields.phoneNumber),
							autoComplete: 'phone number',
							placeholder: '5556661234',
						}}
						errors={fields.phoneNumber.errors}
					/>
					<Field
						labelProps={{ htmlFor: fields.password.id, children: 'Password' }}
						inputProps={{
							...conform.input(fields.password, { type: 'password' }),
							autoComplete: 'new-password',
							placeholder: '************',
						}}
						errors={fields.password.errors}
					/>

					<Field
						labelProps={{
							htmlFor: fields.confirmPassword.id,
							children: 'Confirm Password',
						}}
						inputProps={{
							...conform.input(fields.confirmPassword, { type: 'password' }),
							autoComplete: 'new-password',
							placeholder: '************',
						}}
						errors={fields.confirmPassword.errors}
					/>
					<input {...conform.input(fields.redirectTo, { type: 'hidden' })} />
					<ErrorList errors={form.errors} id={form.errorId} />

					<div className="flex items-center justify-between gap-6">
						<StatusButton
							className="w-full"
							status={isPending ? 'pending' : actionData?.status ?? 'idle'}
							type="submit"
							disabled={isPending}
						>
							Create an account
						</StatusButton>
					</div>
				</Form>
			</div>
		</div>
	)
}
